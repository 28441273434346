export const orders: TableModule = {
    name: "orders",
    route: "spa.orders",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "type",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "w-[65px]"
        },
        {
            name: "order_number",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "w-[140px]"
        },
        {
            name: "created_at",
            sortable: true,
            mobile: true,
            hidden: true,
        },
        {
            name: "tender_number",
            sortable: true,
            mobile: true,
            hidden: true,
        },
        {
            name: "positions",
            pageLink: true,
            sortable: true,
            sortBy: "category_id",
            breakpoint: "xl",
            width: "w-1/4"
        },
        {
            name: "user_id",
            sortable: true,
            breakpoint: "xl"
        },
        {
            name: "cost_center_id",
            sortable: true,
            breakpoint: "md"
        },
        {
            name: "price",
            sortBy: "price",
            sortable: true,
            width: "w-auto xl:w-[130px]"
        },
        {
            name: "status",
            sortBy: "status_id",
            sortable: true,
            mobile: true,
            width: "w-auto xl:w-[170px]"
        },
    ],
    filters: [
        {
            name: "type",
            column: "orders.type",
            type: "select",
            reduced: true,
            options: [
                {
                    value: "order",
                    label: "Beschaffung"
                },
                {
                    value: "special",
                    label: "Sonderbestellung"
                },
                {
                    value: "express",
                    label: "Eilbestellung"
                }
            ]
        },
        {
            name: "order_number",
            column: "orders.order_number",
            type: "numeric",
            reduced: '[,]',
        },
        {
            name: "tender_number",
            column: "orders.tender_number",
            type: "numeric",
        },
        {
            name: "category",
            column: "orders.category_id",
            type: "searchSelect",
            apiRoute: "spa.baseData.categories.index",
        },
        {
            name: "price",
            column: "orders.price",
            type: "numeric",
            reduced: '[,]',
        },
        {
            name: "price_net",
            column: "customFilterPriceNet",
            type: "numeric",
        },
        {
            name: "status",
            column: "orders.status_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.statuses.index')
            ).then(response => response?.map(
                status => ({
                    value: status.id,
                    status: status.name,
                    label: useNuxtApp().$i18n.t('orders.status.' + status.name)
                }))
            ),
            reduced: true,
            multiple: true
        },
        {
            name: "created_at",
            column: "orders.created_at",
            type: "date",
        },
        {
            name: "year",
            column: "customFilterYear",
            type: "numeric"
        },
        {
            name: "reason",
            column: "orders.reason",
            type: "select",
            options: [
                {
                    value: "lowestPrice",
                    label: "Preisgünstigstes Angebot"
                },
                {
                    value: "contractPartner",
                    label: "Rahmenvertragspartner"
                },
                {
                    value: "singleSupplier",
                    label: "Einzelanbieter"
                },
                {
                    value: "bestValue",
                    label: "Wirtschaftlichstes Angebot"
                }
            ]
        }
    ],
    quickFilters: [
        {
            name: "qf_myOrders",
            column: "customFilterMyOrders",
            value: true,
            operator: "="
        },
        {
            name: "qf_toApprove",
            column: "customFilterToApprove",
            value: true,
            operator: "="
        },
        {
            name: "qf_closed",
            column: "customFilterClosed",
            value: true,
            operator: "="
        }
    ],
    tools: [
        {
            name: "orders.create",
            isCreateAction: true,
            ability: ['create', 'orders']
        },
        {
            name: "orders.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "orders.delete",
            color: "rose",
            icon: "heroicons:trash"
        }
    ]
}