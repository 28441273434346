export const organisations: TableModule = {
    name: "organisations",
    route: "spa.settings.organisations",
    columns: [
        {
            name: "name",
            pageLink: true,
            sortable: true,
            sortBy: "name",
            mobile: true
        },
        {
            name: "email",
            sortable: true
        },
        {
            name: "active",
            sortable: true
        },
        {
            name: "created_at",
            sortable: true
        }
    ],
    filters: [
        {
            name: "name",
            column: "organisations.name",
            type: "string",
            reduced: true
        },
        {
            name: "email",
            column: "organisations.email",
            type: "string",
            reduced: true
        },
        {
            name: "active",
            column: "organisations.active",
            type: "select",
            options: [
                {
                    value: true,
                    label: "Aktiv"
                },
                {
                    value: false,
                    label: "Inaktiv"
                }
            ],
            reduced: true
        }
    ],
    quickFilters: [
        {
            name: "isActive",
            column: "organisations.active",
            operator: "=",
            value: true,
            default: true
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "organisations"]
        },
        {
            name: "organisations.toggle"
        },
        {
            name: "general.export"
        }
    ]
}
